import React from 'react'
import './about.css'
import Video from './medias/Sustainable impact Magagascar V2 EN.mp4'
import Logo from './medias/logo.jpg'
const About = () => {
    return (
       <>
        <div className='about' id='about'>
        <div className='container'>
        <h2 className='text-black-50'>About </h2>
                <div className='content'>
                    <div className='card'>
                        <p>Qimpexx, joint venture established in Madagascar, produces sustainably quinine derivatives for pharmaceutical and food industry.
                        Incorporated in 2014, our mission is to become the only world manufacturer of cinchona extracts to integrate its own raw material production. Qimpexx operates a factory for the extraction and the purification of the active ingredients in Fianarantsoa, and develops the cinchona plantation in the same region. The main product, quinine, is used in pharmaceutical anti-malarial products and food industry bitter agent for tonic water.</p>
                    </div>
                </div>
                <br></br>
                <div className='video'>
                <video width="800px" height="416px" controls poster={Logo} config={{ file: { attributes: { controlsList: 'nodownload' } } }}
            // Disable right click
  onContextMenu={e => e.preventDefault()}> 
                    <source src={Video} type="video/mp4"></source>
                </video>
            </div>
        </div>  
        </div>
        </>
    )
}

export default About

