import React from 'react';
import './propos.css';
import Video from '../components/medias/Sustainable impact Magagascar V2 FR.mp4'
import Logo from '../components/medias/logo.jpg'
const Quinine = () => {
    return (
        <>
        <div className='propos' id='propos'>
            <div className='container'>
                <h2 className='text-black-50'>A propos</h2>
                <div className='content'>
                    <div className='card'>
                    <p>QIMPEXX, société basée à Madagascar, produit de manière responsable des principes actifs extraits du quinquina à destination des industries pharmaceutiques et agroalimentaires à l’international.Fondé en 2014, notre mission est de devenir le premier fabricant mondial d’extraits de quinquina intégrant sa propre production de matière première. QIMPEXX opère à la fois sur un volet industriel avec l’extraction et la purification des principes actifs dans son usine de Fianarantsoa, sur un volet agricole en développant la culture du quinquina dans la région.
Le principal produit est la quinine qui est utilisée à des fins pharmaceutiques (traitements contre le paludisme) et agroalimentaires (substance aromatisante pour les boissons toniques).
                    </p>
                    </div> 
                </div>
                <br></br>
                <div className='video'>
                <video width="800px" height="400px" controls poster={Logo} config={{ file: { attributes: { controlsList: 'nodownload' } } }}
            // Disable right click
  onContextMenu={e => e.preventDefault()}> 
                    <source src={Video} type="video/mp4"></source>
                </video>
            </div>
            </div>
        </div>
        </>
    )
}

export default Quinine
