
import React from 'react';
import Home from './components/Home'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Appli from './composants/Appli';
export default function App() {
  return(
    <React.StrictMode>
  <Router forceRefresh={true}>
    <Switch>
      <Route  exact path="/" component={Home} />
      <Route exact path="/fr" component={Appli} />
    </Switch>
  </Router>
  </React.StrictMode>
  )
}
