import React from 'react'
import { Link } from 'react-router-dom';
import Logo from './medias/logo.jpg';
import './home.css';
import { scroller} from 'react-scroll';
const Header = () => {
    return (
        <div className='position-sticky' >
        <nav className='navigation' >
            <Link to="/">
  <label className='logo'><img src={Logo} alt='logo' id='logo'/></label>
            </Link>
  <div className="nav-menu">
    <ul>
      <li onClickCapture={() => scroller.scrollTo('slide',{
        smooth: true, duration:100,
      })}  >Home  
      </li>
      <li  onClickCapture={() => scroller.scrollTo('about',{
      smooth: true, offset: -100, duration:100,
    })}   >About</li>
      <li  onClickCapture={() => scroller.scrollTo('contact',{
        smooth: true, offset: -80,duration:100,
      })}   >Contact</li>

      <Link to="/fr">
      <li><img
  src="https://flagcdn.com/w40/fr.png"
  srcset="https://flagcdn.com/w80/fr.png 2x"
  alt="France" className='drapeau'></img></li>
      </Link>
    </ul>
    </div>
    </nav>
    </div>
    )
}
export default Header
