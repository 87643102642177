import React from "react";
import { Carousel } from "react-bootstrap";
import ImageOne from '../components/medias/1.jpg'
import ImageTwo from '../components/medias/2.jpg'
import ImageThree from '../components/medias/3.jpg'
import ImageFour from '../components/medias/4.jpg'
import ImageFive from '../components/medias/5.jpg'
import ImageSix from '../components/medias/6.jpg'
import Tete from './tete'
function  Anim() {
    return (
        <>
            <Tete/>
            <div className='slide' >
                <Carousel fade pause={false}>
                    <Carousel.Item interval={5000}>
                <img className="d-block w-100" src={ImageOne} alt="First Slide" />
                <Carousel.Caption>
                    <div className="float-lg-start text-start" id="texte">
                        <span>De la graine au principe actif</span> 
                        <p>Qimpexx est le seul producteur mondial de dérivés <br/> naturels de quinine intégrant sa propre <br/> production de matière première, le quinquina.
                        </p>
                    </div>
                </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item interval={5000}>
                    <img className="d-block w-100" src={ImageTwo} alt="Second Slide"  />
                    <Carousel.Caption>
                    <div className="float-lg-start text-start" id="texte">
                    <span>De la graine au principe actif</span> 
                    <p>Qimpexx est le seul producteur mondial de dérivés <br/> naturels de quinine intégrant sa propre <br/> production de matière première, le quinquina.
                        </p>
                    </div>
                    </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item interval={5000}>
                    <img className="d-block w-100" src={ImageThree} alt="Third Slide"  />
                    <Carousel.Caption>
                    <div className="float-lg-start text-start" id="texte">
                    <span>De la graine au principe actif</span> 
                    <p>Qimpexx est le seul producteur mondial de dérivés <br/> naturels de quinine intégrant sa propre <br/> production de matière première, le quinquina.
                        </p>
                    </div>
                    </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item interval={5000}>
                    <img className="d-block w-100" src={ImageFour} alt="Four Slide"  />
                    <Carousel.Caption>
                    <div className="float-lg-start text-start" id="texte">
                    <span>De la graine au principe actif</span> 
                    <p>Qimpexx est le seul producteur mondial de dérivés <br/> naturels de quinine intégrant sa propre <br/> production de matière première, le quinquina.
                        </p>
                    </div>
                    </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item interval={5000}>
                    <img className="d-block w-100" src={ImageFive} alt="Five Slide" />
                    <Carousel.Caption>
                    <div className="float-lg-start text-start" id="texte">
                    <span>De la graine au principe actif</span> 
                    <p>Qimpexx est le seul producteur mondial de dérivés <br/> naturels de quinine intégrant sa propre <br/> production de matière première, le quinquina.
                        </p>
                    </div>
                    </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item interval={5000}>
                    <img className="d-block w-100" src={ImageSix} alt="Six Slide" />
                    <Carousel.Caption>
                    <div className="float-lg-start text-start" id="texte">
                    <span>De la graine au principe actif</span> 
                    <p>Qimpexx est le seul producteur mondial de dérivés <br/> naturels de quinine intégrant sa propre <br/> production de matière première, le quinquina.
                        </p>
                    </div>
                    </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </div>
        </>
    )
    
}
export default Anim