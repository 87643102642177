import React from 'react';
import Logo from '../components/medias/logo.jpg';
import { scroller } from 'react-scroll';
import './accueil.css';
import { Link } from 'react-router-dom';
const Tete = () => {
    return (
      <div className='position-sticky'>
      <nav className='naviguer'>
          <Link to="/fr">
<label className='logo'><img src={Logo} alt='logo' id='logo'></img>
</label>
</Link>
<div  className="nav-links">
  <ul>
    <li onClickCapture={() => scroller.scrollTo('slide',{
      smooth: true, duration:100,
    })}>
    Accueil
    </li>
    <li  onClickCapture={() => scroller.scrollTo('propos',{
      smooth: true, offset: -167, duration:100,
    })} >A propos</li>
    <li  onClickCapture={() => scroller.scrollTo('contact',{
      smooth: true,offset: -80, duration:100,
    })} >Contact</li>

    <Link to="/">
    <li><img
  src="https://flagcdn.com/w40/gb.png"
  srcset="https://flagcdn.com/w80/gb.png 2x"
  width="40"
  height="26"
  alt="Royaume-Uni" className="drapeau"/></li>
    </Link>
  </ul>
  </div>
  </nav>
  </div>
       
    )
}

export default Tete