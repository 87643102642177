import {React} from 'react';
import {Carousel} from 'react-bootstrap';
import ImageOne from './medias/1.jpg';
import ImageTwo from './medias/2.jpg';
import ImageThree from './medias/3.jpg';
import ImageFour from './medias/4.jpg';
import ImageFive from './medias/5.jpg';
import ImageSix from './medias/6.jpg';
import Header from './header';
import './home.css'
function Slider() {

    return (
        <>
            <Header/>
        <div className='slide'>
            <Carousel fade pause={false}>
                <Carousel.Item interval={5000}>
                <img className="d-block w-100 img-responsive"  src={ImageOne}  alt="First Slide"/>
                <Carousel.Caption>
                <div className="float-lg-start  text-start" id='texte'>
                <span >From seed to active ingredient</span> 
                <p>Qimpexx is the only natural quinine derivatives  <br/> manufacturer in the world <br/>to integrate its own cinchona production.</p>
                    </div>
                </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item interval={5000}>
                <img className="d-block w-100" src={ImageTwo}   alt="Second Slide"/>
                <Carousel.Caption>
                <div className="float-lg-start  text-start" id='texte'>
                <span >From seed to active ingredient</span> 
                <p>Qimpexx is the only natural quinine derivatives <br/> manufacturer in the world <br/>to integrate its own cinchona production.</p>
                    </div>
                </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item interval={5000}>
                <img className="d-block w-100" src={ImageThree}   alt="Third Slide"/>
                <Carousel.Caption>
                <div className="float-lg-start  text-start" id='texte'>
                <span >From seed to active ingredient</span> 
                <p>Qimpexx is the only natural quinine derivatives <br/> manufacturer in the world <br/>to integrate its own cinchona production.</p>
                    </div>
                </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item interval={5000}>
                <img className="d-block w-100" src={ImageFour}  alt="Four Slide"/>
                <Carousel.Caption>
                <div className="float-lg-start  text-start" id='texte'>
                <span >From seed to active ingredient</span> 
                <p>Qimpexx is the only natural quinine derivatives <br/> manufacturer in the world <br/>to integrate its own cinchona production.</p>
                    </div>
                </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item interval={5000}>
                <img className="d-block w-100" src={ImageFive}   alt="Five Slide"/>
                <Carousel.Caption>
                <div className="float-lg-start  text-start" id='texte'>
                <span >From seed to active ingredient</span> 
                <p>Qimpexx is the only natural quinine derivatives <br/>  manufacturer in the world <br/>to integrate its own cinchona production.</p>
                    </div>
                </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item interval={5000}>
                <img className="d-block w-100" src={ImageSix}   alt="Six Slide"/>
                <Carousel.Caption>
                <div className="float-lg-start  text-start" id='texte'>
                <span >From seed to active ingredient</span> 
                <p>Qimpexx is the only natural quinine derivatives <br/>  manufacturer in the world <br/>to integrate its own cinchona production.</p>
                    </div>
                </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
        </div>
        </>
    );
}
export default Slider;