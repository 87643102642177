import Anim from './carousel'
import Contacter from "./contact";
import Propos from './propos';
import {React} from 'react';
function Appli() {
  return (
    <>
    <Anim/>
    <Propos/>
    <Contacter/>
    </>
  );
}

export default Appli