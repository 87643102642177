import React from 'react';
import Slider from './slider';
import About from './about';
import Contact from './contact';

function Home() {
    return (
    <>      
                <Slider/>   
                <About/>
                <Contact/>   
    </>
    );
}

export default Home;