import React from 'react'
import './contact.css'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLinkedin} from '@fortawesome/free-brands-svg-icons';
import {faEnvelope, faPhone} from '@fortawesome/free-solid-svg-icons';
const Contact = () => (
        <>
<div className='contact' id='contact'>
        <div className='container'>
                <div className='row'>
                <div className='col-sm-8'>
                        <h1>Factory</h1>
                        <p>Ankijana Fianarantsoa  301 Madagascar.<br/>
                        <FontAwesomeIcon icon={faPhone}/> +261 32 03 022 54<br/><FontAwesomeIcon icon={faEnvelope}/> rh@qimpexx.com</p> 
                        <h1>Headquarters</h1>
                        <p>Immeuble INJET Second floor Tana Water Front<br/>Ambodivona BP 8707,Antananarivo 101- Madagascar.
                        <br/><FontAwesomeIcon icon={faPhone}/>
                     +261 32 05 338 19</p>
                </div>
                <div className='col-sm-4'>
                <h2>
                     Links
                    </h2>
                        <a href='https://www.linkedin.com/company/qimpexx/'>
                    <FontAwesomeIcon icon={faLinkedin} id='links'/></a>
                    
                        <p><a href='https://arnoldsuhr.com/'>arnoldsuhr.com</a></p>
                        <p><a href='https://aqre.group/'>aqre.group.com</a></p>
                </div>
                </div>
                <section>
    <p className='text-center'>
    &copy; 2022 Copyright, All rights reserved. / Crédit Photo: Rabemazava Daniel.</p>
    </section>
                
        </div>
</div>
</>
)

export default Contact
